
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import RequestsFilters from "./RequestsFilters.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "requests-table",
  components: {
    RequestsFilters,
  },
  props: {
    widgetClasses: String,
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedDonorId: null,
      selectedDonorType: "",
      terminationReason: "",
      columns: [],
      entities: [],
      filters: {},
      count: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      loading: false,
      initialized: false,
      selectedAll: false,
    };
  },
  methods: {
    retrieveData() {
      this.initialized = true;
      this.loading = true;
      ApiService.query("requests", {
        params: {
          filters: this.filters,
          perPage: this.pagination.perPage,
          page: this.pagination.page,
        },
      }).then((response) => {
        this.$emit("onRetrieve", response.data);
        this.loading = false;
        this.entities = response.data.data;
        this.columns = response.data.columns;
        this.pagination.total = response.data.meta.total;
        this.count = response.data.meta.total;
      }).catch((error) => {
        console.error("Error fetching inactivation data:", error);
      });
    },
    getEntityValue(entity, columnName) {
      // Obtiene el valor dinámico para una columna
      return  columnName === 'response_time' ?  entity[columnName]  ?? "-" : entity[columnName]  ?? "N/A";
    },
    getColumnClass(column) {
      return column.sortable ? "sortable-column" : "";
    },
    getCustomColumnClass(columnName) {
      switch (columnName) {
        case "type":
          return "column-type";
        case "status":
          return "column-status";
        default:
          return "";
      }
    },
    getTypeText(status) {
      switch (status) {
        case "donor_inactivations":
          return "Baja";
        case "donor_reactivations":
          return "Reactivación";
        case "donor_suspentions":
          return "Suspensión";
        case "donor":
          return "Alta";
        case "preload_drafts":
          return "Pre-carga";
        default:
          return "Desconocido";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "pending":
          return "warning";
        case "approved":
          return "success";
        case "rejected":
          return "danger";
        default:
          return "dark";
      }
    },
    translateStatus(status) {
      const statusTranslations = {
        pending: "Pendiente",
        approved: "Aprobado",
        rejected: "Rechazado",
      };
      return statusTranslations[status] || "Desconocido"; // Devuelve "Desconocido" si no coincide
    },
    updateFilters(newFilters) {
      this.filters = newFilters;
      this.retrieveData();
    },
    formatDate(date) {
      // Ajustar formato de fecha
      return new Date(date).toLocaleDateString();
    },
  },
  mounted() {
    this.retrieveData();
  },
  setup() {
    const InactivationCheckModal = ref<null | HTMLElement>(null);

    const store = useStore();

    return  {
      InactivationCheckModal
    }

  }
});
