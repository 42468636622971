
  import { defineComponent, ref, watch } from "vue";
  
  export default defineComponent({
    name: "requests-filters",
    props: {
      applied_filters: Object,
      allowed_filters: {
        type: Array,
        required: false,
        default: () => {
          return [
            "type",
            "status",
            "creationDate",
            "donorType",
          ];
        },
      },
    },
    data() {
      return {
        statusOptions: {
          pending: "Pendiente",
          approved: "Aprobado",
          rejected: "Rechazado"
        },
        typeOptions: {
          donor: "Alta",
          donor_inactivations: "Baja",
          donor_reactivations: "Reactivación",
          donor_suspentions: "Suspensión",
          preload_drafts: "Pre-carga",
        },
        filters: {
          type: "",
          creationDate: [],
          status: "",
          donorType: "",
        },
      };
    },
    emits: ["changed"],
    methods: {
      resetFilters() {
        this.filters = {
          type: "",
          creationDate: [],
          status: "",
          donorType: ""
        };
        this.dataChanged();
      },
      dataChanged() {
        const payload = {
          ...this.filters,
          creation_date: this.filters.creationDate?.length
            ? [this.filters.creationDate[0], this.filters.creationDate[1]]
            : undefined,
        };
        this.$emit("changed", payload);
      },
    },
    mounted() {
      watch(this.filters, () => {
        this.dataChanged();
      });
  
      (this.filters as any) = this.applied_filters;
    },
  });
  